import React from "react";
import "./index.css";
import { ArrowDown } from "../../assets/icons";
import VideoBg from "./VideoBg";
function Home() {
  return (
    <VideoBg>
      <div className="home-content">
        <div className="home-title">AI Hyperfusion Layer</div>
        <div className="home-desc">
          Decentralized AI Superfusion Service，Linking AI Models with Smart
          Contracts
        </div>
        <div className="home-arrow-wrap">
          <div className="home-arrow-desc">scroll to explore</div>
          <ArrowDown />
        </div>
      </div>
    </VideoBg>
  );
}

export default Home;
