import Logo from "./logo.png";
import AILinkAvatar from './AILinkAvatar.webp'
import AILinkLoop from './AILinkLoop.webp'
import PcTech from './pc-tech.png'

const IMAGES = {
  Logo,
  AILinkAvatar,
  AILinkLoop,
  PcTech,
};
export default IMAGES;
