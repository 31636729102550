import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import Logo from "../Logo";

function Header() {
  const handleWhitePaperClicked = () => {
    window.open('https://d1yv70807uke08.cloudfront.net/whitepaper/AI_Hyperfusion_Layer_White_Paper.pdf', "_blank");
  };

  const handleGetTokenClicked = () => {
    alert("Coming Soon");
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  const [headerContentTop, setHeaderContentTop] = useState(0);

  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRef = useRef(null);
  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    const homeContent = document.querySelector(".home-content");
    const { top } = homeContent.getBoundingClientRect();
    setHeaderContentTop(top);
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      setScrollPosition(currentPosition);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={` header ${
        scrollPosition > headerContentTop - headerHeight ? "scrolled" : ""
      }`}
      ref={headerRef}
    >
      <div className="container header-wrap">
        <div className="menu-box">
          <Logo title="AILink" color="white" />
        </div>
        <div className="wallet-btn-wrap">
          <div
            className="wallet-btn white-paper-btn"
            onClick={handleWhitePaperClicked}
          >
            White papge
          </div>
          <div
            className="wallet-btn get-token-btn"
            onClick={handleGetTokenClicked}
          >
            Get token
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
