import React from "react";
import "./index.css";
import IMAGES from "../../assets/images";
import { MobileTech1, MobileTech2, MobileTech3 } from "../../assets/icons";
function Tech() {
  const isMobile = /Mobi|Android|iPhone/i.test(navigator.userAgent);

  const pcRender = () => {
    return <img src={IMAGES.PcTech} alt="PC-Tech" />;
  };

  const mobileRender = () => {
    const Icon1 = MobileTech1;
    const Icon2 = MobileTech2;
    const Icon3 = MobileTech3;
    return (
      <React.Fragment>
        <Icon1 className="svg-img" />
        <div>
          <Icon2 className="svg-img" />
          <div className="tech-mobilc-desc">
            Neural Discrete Representation Learning
          </div>
        </div>
        <div>
          <Icon3 className="svg-img" />
          <div className="tech-mobilc-desc">
            Leaning Realistic 3D Motion Coefficients for Stylized Audio-Driven
            Single Image Talking Face Animation
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="container tech-wrap">
      <div className="tech-title">Technology Architecture</div>
      <div className="tech-list-wrap">
        {isMobile ? mobileRender() : pcRender()}
      </div>
    </div>
  );
}

export default Tech;
