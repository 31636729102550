import React from "react";
import "./index.css";
import { Discord, Twitter, Telegram } from "../../assets/icons/index";
import Logo from "../Logo";
function Footer() {
  function redirectToExternalLink(url) {
    if (url) {
      window.open(url, "_blank");
    } else {
      alert("Coming Soon");
    }
  }

  return (
    <div className="container footer">
      <Logo title="AILink" color="#0D1242" />
      <div className="footer-right-wrap">
        <Discord
          className="icon-share"
          onClick={() => redirectToExternalLink()}
        />
        <Twitter
          className="icon-share"
          onClick={() => redirectToExternalLink()}
        />
        <Telegram
          className="icon-share"
          onClick={() => redirectToExternalLink()}
        />
      </div>
    </div>
  );
}

export default Footer;
