import "./App.css";
import Header from "./components/Header";
import Home from "./components/Home";
import Feature from "./components/Feature";
import Tech from "./components/Tech";
import Intro from "./components/Intro";
import Footer from "./components/Footer";
import React, { useEffect } from "react";

function App() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  return (
    <div className="App">
      <Header />
      <Home />
      <Feature />
      <Tech />
      <Intro />
      <Footer />
    </div>
  );
}

export default App;
