import React from "react";
import "./index.css";
import { Func1, Func2, Func3, Func4 } from "../../assets/icons";
function Feature() {
  const features = [
    {
      id: 1,
      icon: Func1,
      title: "Linking AI Models and Smart Contracts",
      desc: "Provides an on-chain component for smart contracts to access AI models through external connections, as well as software that drives network nodes to operate. It assists smart contracts in easily and quickly integrating AI models without the need for substantial investment.",
    },
    {
      id: 2,
      icon: Func2,
      title: "Distributed Computing",
      desc: "In the AI.H Layer network, a decentralized distributed computing strategy is adopted to enhance system robustness and mitigate errors generated by AI, as well as other potential faults.",
    },
    {
      id: 3,
      icon: Func3,
      title: "Protocols Support",
      desc: "Al Hyperfusion Layer supports popular Bitcoinprotocols such as BRC20,BRC420, Bitmap, Atomicals, Pipe, Stamp, and more, enabling a more extensive use!base to interact on Bitcoin Layer2",
    },
    {
      id: 4,
      icon: Func4,
      title: "Community Governance",
      desc: "The AI Hyperfusion Layer will implement community self-governance through the governance token $AIHL. 20% of $AIHL will be distributed in the near future as rewards for early supporters of the network.",
    },
  ];

  const featureItem =  (feature) => {
    const Icon = feature.icon
    return (
      <div key={feature.id} className="feature-item-wrap">
        <Icon />
        <div className="feature-item-title">{feature.title}</div>
        <div className="feature-item-desc">{feature.desc}</div>
      </div>
    )
  }

  return (
    <div className="container feature-wrap">
      <div className="feature-title">Cutting-edge Platform</div>
      <div className="feature-list-wrap">
        {features.map(feature => featureItem(feature))}
      </div>
    </div>
  );
}

export default Feature;
