import React, { useState, useEffect } from "react";
import "./VideoBg.css";
import AILinkAvatar_PC from "../../assets/AILinkAvatar_PC.webm";
import AILinkLoop_PC from "../../assets/AILinkLoop_PC.webm";
import AILinkAvatar_MB from "../../assets/AILinkAvatar_MB.webm";
import AILinkLoop_MB from "../../assets/AILinkLoop_MB.webm";
function VideoBg({ children }) {
  const isMobile = /Mobi|Android|iPhone/i.test(navigator.userAgent);

  const [currentVideo, setCurrentVideo] = useState(
    isMobile ? AILinkAvatar_MB : AILinkAvatar_PC
  );
  useEffect(() => {
    const firstVideo = document.getElementById("firstVideo");
    const secondVideo = document.getElementById("secondVideo");

    firstVideo.addEventListener("ended", () => {
      const video = isMobile ? AILinkLoop_MB : AILinkLoop_PC;
      setCurrentVideo(video);
      secondVideo.play();
      firstVideo.style.opacity = 0;
    });
  }, [currentVideo, isMobile]);

  return (
    <div className="video-background">
      <video
        id="firstVideo"
        preload="none"
        autoPlay
        muted
        playsInline
        style={{ zIndex: 1 }}
      >
        <source
          src={isMobile ? AILinkAvatar_MB : AILinkAvatar_PC}
          type="video/webm"
        />
      </video>
      <video
        id="secondVideo"
        preload="none"
        muted
        playsInline
        loop
        style={{ zIndex: 0 }}
      >
        <source
          src={isMobile ? AILinkLoop_MB : AILinkLoop_PC}
          type="video/webm"
        />
      </video>
      {children}
    </div>
  );
}

export default VideoBg;
