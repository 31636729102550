import React from "react";
import "./index.css";
import { Intro1, Intro2, Intro3 } from "../../assets/icons";
function Intro() {
  const intros = [
    {
      id: 1,
      icon: Intro1,
      title: "Bridging Smart Contracts with AI Big Models",
      desc: "The AI.H Layer aims to establish a bridge between smart contracts and artificial intelligence big models, achieving the fusion and interaction of two technical fields. The AI.H Layer can combine the immutability, transparency, and security of blockchain with the powerful analysis and prediction capabilities of AI models, making smart contracts smarter.",
    },
    {
      id: 2,
      icon: Intro2,
      title: "Supporting Cross-Chain and Off-Chain Interactions",
      desc: "Designed with the diversity and complexity of the blockchain ecosystem in mind, the AI.H Layer natively supports cross-chain functionality. This means that the AI.H Layer can operate not only on a single platform like Ethereum but also connect and support other networks such as Polkadot and BSC (Binance Smart Chain), greatly increasing its application flexibility and breadth.",
    },
    {
      id: 3,
      icon: Intro3,
      title: "Component Modularization, Upgradable and Replaceable",
      desc: "The AI.H Layer adopts a modular design philosophy, where all components are independent and replaceable. This design allows the system to flexibly respond to technological advancements and market changes, continuously optimizing performance and functionality by replacing or upgrading its internal components according to the latest technological developments or more efficient algorithms, ensuring the AI.H Layer network always remains at the forefront of the industry",
    },
  ];

  const introItem = (intro) => {
    const Icon = intro.icon;
    return (
      <div key={intro.id} className="intro-item-wrap">
        <Icon />
        <div className="intro-item-title">{intro.title}</div>
        <div className="intro-item-desc">{intro.desc}</div>
      </div>
    );
  };

  return (
    <div className="container intro-wrap">
      <div className="intro-title">Technical Advantages</div>
      <div className="intro-list-wrap">
        {intros.map((intro) => introItem(intro))}
      </div>
    </div>
  );
}

export default Intro;
